import { urlEncode, urlDecode } from "../url-encode/url-encode";

export const DEBUG_KEYS = [
  "locate",
  "cities",
  "genres",
  "cityEvents",
  "cityOrganizations",
  "eventDetails",
  "venueDetails"
];

export const DEBUG_LOADING = "loading";

export const DEBUG_ERROR = "error";

export const getDebugStateFromSearch = search => {
  const searchParams = urlDecode(search);
  const { debug = false } = searchParams;
  const debugState = {
    debug
  };
  delete searchParams.debug;
  DEBUG_KEYS.forEach(key => {
    let keyState = searchParams[key];
    if (keyState !== DEBUG_LOADING && keyState !== DEBUG_ERROR) {
      keyState = null;
    }
    debugState[key] = keyState;
    delete searchParams[key];
  });
  return { debugState, otherParts: searchParams };
};

export const getSearchFromDebugState = (oldSearch, debugState) => {
  const oldSearchParams = urlDecode(oldSearch || "");
  DEBUG_KEYS.forEach(key => {
    delete oldSearchParams[key];
  });
  const { debug } = debugState;
  const searchParams = {
    debug
  };
  DEBUG_KEYS.forEach(key => {
    let keyState = debugState[key];
    if (keyState === DEBUG_LOADING || keyState === DEBUG_ERROR) {
      searchParams[key] = keyState;
    }
  });
  const search = urlEncode({ ...oldSearchParams, ...searchParams });
  return search;
};
