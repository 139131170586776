import sizer from "react-sizer";

const breakpoints = {
  extraExtraSmall: 0,
  extraSmall: 340,
  small: 576,
  medium: 768,
  large: 992,
  extraLarge: 1200
};
const breakpointSizes = Object.values(breakpoints).reverse();

export const resposniveIsExtraExtraSmall = breakpoint => breakpoint === breakpoints.extraExtraSmall;
export const responsiveIsExtraSmall = breakpoint => breakpoint === breakpoints.extraSmall;
export const responsiveIsSmall = breakpoint => breakpoint === breakpoints.small;
export const responsiveIsMedium = breakpoint => breakpoint === breakpoints.medium;
export const responsiveIsLarge = breakpoint => breakpoint === breakpoints.large;
export const responsiveIsExtraLarge = breakpoint => breakpoint === breakpoints.extraLarge;

export const responsiveLessThanExtraSmall = breakpoint => breakpoint < breakpoints.extraSmall;
export const responsiveLessThanSmall = breakpoint => breakpoint < breakpoints.small;
export const responsiveLessThanMedium = breakpoint => breakpoint < breakpoints.medium;
export const responsiveLessThanLarge = breakpoint => breakpoint < breakpoints.large;
export const responsiveLessThanExtraLarge = breakpoint => breakpoint < breakpoints.extraLarge;

export const responsiveGreaterThanExtraExtraSmall = breakpoint => breakpoint > breakpoints.extraExtraSmall;
export const responsiveGreaterThanExtraSmall = breakpoint => breakpoint > breakpoints.extraSmall;
export const responsiveGreaterThanSmall = breakpoint => breakpoint > breakpoints.small;
export const responsiveGreaterThanMedium = breakpoint => breakpoint > breakpoints.medium;
export const responsiveGreaterThanLarge = breakpoint => breakpoint > breakpoints.large;

/*
  Walk sizes backwards until the width is greater than a breakpoint.  Once that boundary is crossed,
  return the fixed breakpoint position integer as the key so that it can be used for comparison.

  The breakpoints must be walked backwards because there is no value for infinity, but the values can
  bottom out at 0 with breakpoints.extraExtraSmall.
*/
const getResponsiveBreakpointForSize = ({ width }) => {
  let breakpoint = breakpoints.extraExtraSmall;
  for (let size of breakpointSizes) {
    if (width >= size) {
      breakpoint = size;
      break;
    }
  }
  return { responsiveBreakpoint: breakpoint };
};

export const withResponsiveBreakpoint = WrappedComponent =>
  sizer({ getSizeProps: getResponsiveBreakpointForSize })(WrappedComponent);
