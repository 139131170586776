import React, { Fragment, Component } from "react";
import memoize from "memoize-one";
import { Button } from "reactstrap";

import { withResponsiveBreakpoint, responsiveLessThanLarge } from "../responsive/responsive-sizer";

import { FetchProvider } from "../fetch";
import { ICON_TICKET_TROVE, ICON_ENABLED, ICON_DISABLED } from "../components/icons/icons";
import { USE_VECTOR_MAP } from "../location/constants";

import {
  DEBUG_KEYS,
  DEBUG_LOADING,
  DEBUG_ERROR,
  getDebugStateFromSearch,
  getSearchFromDebugState
} from "../data/debug";

const BRAND_LABEL = "Ticket Trove";

const BRAND_ITEM = {
  path: "/events",
  label: BRAND_LABEL,
  icon: <ICON_TICKET_TROVE />
};

const INVERSE = false;

const PRIMARY_LEFT_ITEMS = [
  {
    id: "events",
    label: "Events",
    path: "/events"
  },
  {
    id: "venues",
    label: "Venues",
    path: "/venues"
  }
];

const PRIMARY_SEPARATED_LEFT_ITEMS = [
  {
    id: "separator",
    className: "nav-item__separator"
  }
].concat(PRIMARY_LEFT_ITEMS);

class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
      useVectorMap: USE_VECTOR_MAP
    };
  }

  getUseVectorMapItem = memoize(useVectorMap => {
    return {
      id: "use-vector-map",
      label: "Use Vector Map",
      icon: useVectorMap ? ICON_ENABLED : ICON_DISABLED,
      onClick: () => {
        this.setState({ useVectorMap: !useVectorMap });
      }
    };
  });

  getDebugItem = (debugState, location, history) => {
    if (debugState && debugState.debug) {
      const { pathname, search } = location;
      return {
        id: "debug",
        label: "Debug",
        items: DEBUG_KEYS.map(debugKey => {
          const keyState = debugState[debugKey];
          const keyLoading = keyState === DEBUG_LOADING;
          const keyError = keyState === DEBUG_ERROR;
          return {
            id: "debug-" + debugKey,
            label: (
              <Fragment>
                {debugKey + (keyLoading ? " (L)" : keyError ? " (E)" : "")}
                <Button
                  disabled={keyLoading}
                  onClick={e => {
                    e.stopPropagation();
                    history.push({
                      pathname,
                      search: getSearchFromDebugState(search, { ...debugState, [debugKey]: DEBUG_LOADING })
                    });
                  }}
                >
                  L
                </Button>
                <Button
                  disabled={keyError}
                  onClick={e => {
                    e.stopPropagation();
                    history.push({
                      pathname,
                      search: getSearchFromDebugState(search, { ...debugState, [debugKey]: DEBUG_ERROR })
                    });
                  }}
                >
                  E
                </Button>
                <Button
                  disabled={!keyLoading && !keyError}
                  onClick={e => {
                    e.stopPropagation();
                    history.push({
                      pathname,
                      search: getSearchFromDebugState(search, { ...debugState, [debugKey]: null })
                    });
                  }}
                >
                  R
                </Button>
              </Fragment>
            ),
            onClick: () => {}
          };
        })
      };
    }
    return null;
  };

  getPrimaryLeftItems = memoize((debugState, location, history, isSmall) => {
    const primaryLeftItems = isSmall ? PRIMARY_LEFT_ITEMS : PRIMARY_SEPARATED_LEFT_ITEMS;
    const debugItem = this.getDebugItem(debugState, location, history);
    if (debugItem) {
      return [...primaryLeftItems, debugItem];
    }
    return primaryLeftItems;
  });

  getDebugStateFromSearch = memoize(location => {
    return getDebugStateFromSearch(location && location.search ? location.search : "").debugState;
  });

  render() {
    const { renderChild, responsiveBreakpoint, ...otherProps } = this.props;
    const { location, history } = this.props;
    const { useVectorMap } = this.state;
    const debugState = this.getDebugStateFromSearch(location);
    const isSmall = responsiveLessThanLarge(responsiveBreakpoint);
    const primaryLeftItems = this.getPrimaryLeftItems(debugState, location, history, isSmall);
    const useVectorMapItem = this.getUseVectorMapItem(useVectorMap);

    return (
      <FetchProvider>
        {renderChild({
          ...otherProps,
          responsiveBreakpoint,
          debugState,
          inverse: INVERSE,
          brandItem: BRAND_ITEM,
          primaryLeftItems: primaryLeftItems,
          primaryRightItems: null,
          togglerIcon: (
            <div className="hamburger hamburger--squeeze">
              <div className="hamburger-box">
                <div className="hamburger-inner"></div>
              </div>
            </div>
          ),
          useVectorMap,
          useVectorMapItem
        })}
      </FetchProvider>
    );
  }
}

let ResponsiveRoot = withResponsiveBreakpoint(Root);
export default ResponsiveRoot;
