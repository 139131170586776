const ARRAY_JOIN_CHAR = ",";
const EMPTY_OBJECT = {};

export const urlEncode = obj => {
  return Object.keys(obj)
    .map(key => {
      const val = obj[key];
      if (Array.isArray(val)) {
        return key + "=" + val.map(encodeURIComponent).join(ARRAY_JOIN_CHAR);
      } else {
        return key + "=" + encodeURIComponent(val);
      }
    })
    .join("&");
};

export const urlDecode = str => {
  if (str && str.startsWith("?")) {
    str = str.substring(1);
  }
  if (str) {
    const result = {};
    const parts = str.split("&");
    parts.forEach(part => {
      const [key, value] = part.split("=");
      if (value.indexOf(ARRAY_JOIN_CHAR) !== -1) {
        result[key] = value.split(ARRAY_JOIN_CHAR).map(decodeURIComponent);
      } else {
        result[key] = decodeURIComponent(value);
      }
    });
    return result;
  } else {
    return EMPTY_OBJECT;
  }
};

const FILTER_GENRES = "genres";
const FILTER_DATES = "dates";
const FILTER_TIMES = "times";

export const encodeGenre = genre => encodeURIComponent(genre);

export const decodeGenre = genre => decodeURIComponent(genre);

export const encodeCitySlug = citySlug => urlEncode({ city: citySlug });

export const decodeCitySlug = encodedCitySlug => urlDecode(encodedCitySlug).city;

export const encodeFilters = ({ genreFilters = [], dateFilters = [], timeFilters = [], ...otherParts }) => {
  const filterParts = { ...otherParts };
  if (genreFilters.length > 0) {
    filterParts[FILTER_GENRES] = genreFilters;
  }
  if (dateFilters.length > 0) {
    filterParts[FILTER_DATES] = dateFilters;
  }
  if (timeFilters.length > 0) {
    filterParts[FILTER_TIMES] = timeFilters;
  }
  return urlEncode(filterParts);
};

const forceArray = val => (Array.isArray(val) ? val : [val]);

export const decodeFilters = (encodedFilters = "") => {
  let genreFilters = [];
  let dateFilters = [];
  let timeFilters = [];
  const filterParts = urlDecode(encodedFilters);
  if (filterParts[FILTER_GENRES]) {
    genreFilters = forceArray(filterParts[FILTER_GENRES]);
  }
  if (filterParts[FILTER_DATES]) {
    dateFilters = forceArray(filterParts[FILTER_DATES]);
  }
  if (filterParts[FILTER_TIMES]) {
    timeFilters = forceArray(filterParts[FILTER_TIMES]);
  }
  delete filterParts[FILTER_GENRES];
  delete filterParts[FILTER_DATES];
  delete filterParts[FILTER_TIMES];
  return { genreFilters, dateFilters, timeFilters, otherParts: filterParts };
};
