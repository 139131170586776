import React, { Component } from "react";

import { FontAwesomeIcon as FontAwesome } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare, faCaretSquareDown, faCaretSquareRight } from "@fortawesome/free-regular-svg-icons";
import { faUnlink, faCog, faSpinner, faExclamationTriangle, faHome, faUser } from "@fortawesome/free-solid-svg-icons";

function createIcon(icon, { fixedWidth = true, space = true, hidden = false, spin = false, rotate = null } = {}) {
  return (
    <span className={hidden ? "d-none" : null}>
      <FontAwesome fixedWidth={fixedWidth} icon={icon} spin={spin} rotation={rotate} />
      {space === true ? " " : null}
    </span>
  );
}

export const ICON_ENABLED = createIcon(faCheckSquare);

export const ICON_DISABLED = createIcon(faSquare);

export const NAV_GROUP_OPENED_ICON = createIcon(faCaretSquareDown);

export const NAV_GROUP_CLOSED_ICON = createIcon(faCaretSquareRight);

export const ICON_BROKEN = createIcon(faUnlink);

export const ICON_SPACER = createIcon(faCog, { hidden: true });

export const ICON_SETTINGS = createIcon(faCog);

export const ICON_LOADING = createIcon(faSpinner);

export const ICON_SPINNER = createIcon(faSpinner, { spin: true });

export const ICON_ERROR = createIcon(faExclamationTriangle);

export const ICON_HOME = createIcon(faHome);

export const ICON_USER = createIcon(faUser);

export class ICON_TICKET_TROVE extends Component {
  /*
  Future Suggestion: Make fonts objects so that they can be called by

  ICON.<ICON_FAMILY>.<ICON_NAME>

  Example:

  <ICON.FONTAWESOME.SETTINGS/>
*/
  //
  // Material Community Icons:
  // Ticket Outline
  //
  // https://materialdesignicons.com/icon/ticket-outline
  //
  // SIL: OFL
  // https://github.com/Templarian/MaterialDesign/blob/master/LICENSE
  //

  render() {
    const style = this.props.style;
    const fill = this.props.fill ? this.props.fill : "#000000";
    const height = this.props.height ? this.props.height : 28;
    const width = this.props.width ? this.props.width : 55;
    const stroke = this.props.stroke ? this.props.stroke : "none";
    const strokeWidth = this.props.strokeWidth ? this.props.strokeWidth : null;
    const viewBox = this.props.viewBox ? this.props.viewBox : "0 4 22 18";

    return (
      <svg
        className={"material-community-icons ticket-outline"}
        xmlns="http://www.w3.org/2000/svg"
        style={style}
        fill={fill}
        width={width}
        height={height}
        stroke={stroke}
        strokeWidth={strokeWidth}
        viewBox={viewBox}
      >
        <path d="M4,4A2,2 0 0,0 2,6V10A2,2 0 0,1 4,12A2,2 0 0,1 2,14V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V14A2,2 0 0,1 20,12A2,2 0 0,1 22,10V6A2,2 0 0,0 20,4H4M4,6H20V8.54C18.76,9.25 18,10.57 18,12C18,13.43 18.76,14.75 20,15.46V18H4V15.46C5.24,14.75 6,13.43 6,12C6,10.57 5.24,9.25 4,8.54V6Z" />
      </svg>
    );
  }
}

export class ICON_CURRENT_LOCATION extends Component {
  //
  // Simple Line Icons:
  // Cursor
  //
  // https://iconify.design/icon-sets/simple-line-icons/
  //
  // MIT
  // https://github.com/thesabbir/simple-line-icons/blob/master/LICENSE.md
  //

  render() {
    const style = { marginRight: 10, ...(this.props.style ? this.props.style : {}) };
    const fill = this.props.fill ? this.props.fill : "#000000";
    const height = this.props.height ? this.props.height : 17;
    const width = this.props.width ? this.props.width : 17;
    const stroke = this.props.stroke ? this.props.stroke : "none";
    const strokeWidth = this.props.strokeWidth ? this.props.strokeWidth : 20;
    const viewBox = this.props.viewBox ? this.props.viewBox : "0 0 1024 1024";

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={"simple-line-icons cursor " + this.props.className}
        style={style}
        fill={fill}
        width={width}
        height={height}
        stroke={stroke}
        strokeWidth={strokeWidth}
        viewBox={viewBox}
      >
        <path d="M921 103L585 889 466 545 121 440zm83-102q-9 0-22 5L28 411Q2 422 .5 440.5T23 474l395 123 130 404q13 22 31 22 19 0 31-27l408-954q8-19 4-30t-18-11z" />
      </svg>
    );
  }
}

export class ICON_SUNRISE extends Component {
  //
  // Feather Icons:
  // Sunrise
  //
  // https://feathericons.com/#sunrise
  //
  // MIT
  // https://github.com/feathericons/feather/blob/master/LICENSE
  //

  render() {
    const style = this.props.style;
    const fill = this.props.fill ? this.props.fill : "none";
    const height = this.props.height ? this.props.height : 24;
    const width = this.props.width ? this.props.width : 24;
    const stroke = this.props.stroke ? this.props.stroke : "#000000";
    const strokeWidth = this.props.strokeWidth ? this.props.strokeWidth : 2;
    const viewBox = this.props.viewBox ? this.props.viewBox : "0 -4 22 28";

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={"feather-icons sunrise"}
        style={style}
        width={width}
        height={height}
        viewBox={viewBox}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M17 18a5 5 0 0 0-10 0" />
        <line x1="12" y1="2" x2="12" y2="9" />
        <line x1="4.22" y1="10.22" x2="5.64" y2="11.64" />
        <line x1="1" y1="18" x2="3" y2="18" />
        <line x1="21" y1="18" x2="23" y2="18" />
        <line x1="18.36" y1="11.64" x2="19.78" y2="10.22" />
        <line x1="23" y1="22" x2="1" y2="22" />
        <polyline points="8 6 12 2 16 6" />
      </svg>
    );
  }
}

export class ICON_SUN extends Component {
  //
  // Feather Icons:
  // Sun
  //
  // https://feathericons.com/#sun
  //
  // MIT
  // https://github.com/feathericons/feather/blob/master/LICENSE
  //

  render() {
    const style = this.props.style;
    const fill = this.props.fill ? this.props.fill : "none";
    const height = this.props.height ? this.props.height : 24;
    const width = this.props.width ? this.props.width : 24;
    const stroke = this.props.stroke ? this.props.stroke : "#000000";
    const strokeWidth = this.props.strokeWidth ? this.props.strokeWidth : 2;
    const viewBox = this.props.viewBox ? this.props.viewBox : "0 -4 22 28";

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={"feather-icons sun"}
        style={style}
        width={width}
        height={height}
        viewBox={viewBox}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="12" cy="12" r="5" />
        <line x1="12" y1="1" x2="12" y2="3" />
        <line x1="12" y1="21" x2="12" y2="23" />
        <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
        <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
        <line x1="1" y1="12" x2="3" y2="12" />
        <line x1="21" y1="12" x2="23" y2="12" />
        <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
        <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
      </svg>
    );
  }
}

export class ICON_MOON extends Component {
  //
  // Feather Icons:
  // Moon
  //
  // https://feathericons.com/#moon
  //
  // MIT
  // https://github.com/feathericons/feather/blob/master/LICENSE
  //

  render() {
    const style = this.props.style;
    const fill = this.props.fill ? this.props.fill : "none";
    const height = this.props.height ? this.props.height : 24;
    const width = this.props.width ? this.props.width : 24;
    const stroke = this.props.stroke ? this.props.stroke : "#000000";
    const strokeWidth = this.props.strokeWidth ? this.props.strokeWidth : 2;
    const viewBox = this.props.viewBox ? this.props.viewBox : "0 -4 22 26";

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={"feather-icons moon"}
        style={style}
        width={width}
        height={height}
        viewBox={viewBox}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" />
      </svg>
    );
  }
}

export class ICON_CHEVRON_THIN_RIGHT extends Component {
  //
  // Entypo Icons:
  // Chevron Thin Right
  //
  // http://www.entypo.com/index.php
  //
  // CC BY-SA 4.0
  // http://www.entypo.com/faq.php
  //

  render() {
    const style = this.props.style;
    const fill = this.props.fill ? this.props.fill : "#000000";
    const height = this.props.height ? this.props.height : null;
    const width = this.props.width ? this.props.width : null;
    const stroke = this.props.stroke ? this.props.stroke : null;
    const strokeWidth = this.props.strokeWidth ? this.props.strokeWidth : null;
    const viewBox = this.props.viewBox ? this.props.viewBox : "0 0 20 24";

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={"entypo-icons chevron-thin-right"}
        style={style}
        width={width}
        height={height}
        viewBox={viewBox}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
      >
        <path d="M13.25,10L6.109,2.58c-0.268-0.27-0.268-0.707,0-0.979c0.268-0.27,0.701-0.27,0.969,0l7.83,7.908 c0.268,0.271,0.268,0.709,0,0.979l-7.83,7.908c-0.268,0.271-0.701,0.27-0.969,0c-0.268-0.269-0.268-0.707,0-0.979L13.25,10z" />
      </svg>
    );
  }
}

export class ICON_CHEVRON_RIGHT extends Component {
  //
  // Feather Icons:
  // Chevron Right
  //
  // https://feathericons.com/#chevron-right
  //
  // MIT
  // https://github.com/feathericons/feather/blob/master/LICENSE
  //

  render() {
    const style = this.props.style;
    const fill = this.props.fill ? this.props.fill : "none";
    const height = this.props.height ? this.props.height : 24;
    const width = this.props.width ? this.props.width : 24;
    const stroke = this.props.stroke ? this.props.stroke : "#000000";
    const strokeWidth = this.props.strokeWidth ? this.props.strokeWidth : 2;
    const viewBox = this.props.viewBox ? this.props.viewBox : "0 0 24 24";

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={style}
        width={width}
        height={height}
        viewBox={viewBox}
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        className="feather-icons chevron-right"
      >
        <path d="M9 18L15 12 9 6" />
      </svg>
    );
  }
}

export class ICON_CHEVRON_DOWN extends Component {
  //
  // Feather Icons:
  // Chevron Down
  //
  // https://feathericons.com/#chevron-down
  //
  // MIT
  // https://github.com/feathericons/feather/blob/master/LICENSE
  //

  render() {
    const style = this.props.style;
    const fill = this.props.fill ? this.props.fill : "none";
    const height = this.props.height ? this.props.height : 24;
    const width = this.props.width ? this.props.width : 24;
    const stroke = this.props.stroke ? this.props.stroke : "#000000";
    const strokeWidth = this.props.strokeWidth ? this.props.strokeWidth : 2;
    const viewBox = this.props.viewBox ? this.props.viewBox : "0 0 24 24";

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={"feather-icons chevron-down " + this.props.className}
        style={style}
        width={width}
        height={height}
        viewBox={viewBox}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="6 9 12 15 18 9" />
      </svg>
    );
  }
}

export class ICON_TARGET extends Component {
  //
  // Material Design Community Icons:
  // Target
  //
  // https://materialdesignicons.com/
  //
  // SIL: OFL
  // https://github.com/Templarian/MaterialDesign/blob/master/LICENSE
  //

  render() {
    const style = this.props.style;
    const fill = this.props.fill ? this.props.fill : "#000000";
    const height = this.props.height ? this.props.height : 24;
    const width = this.props.width ? this.props.width : 24;
    const stroke = this.props.stroke ? this.props.stroke : null;
    const strokeWidth = this.props.strokeWidth ? this.props.strokeWidth : 1;
    const viewBox = this.props.viewBox ? this.props.viewBox : "0 0 24 24";

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={"material-design-icons target " + this.props.className}
        style={style}
        width={width}
        height={height}
        viewBox={viewBox}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M11 2v2.07A8.002 8.002 0 004.07 11H2v2h2.07A8.002 8.002 0 0011 19.93V22h2v-2.07A8.002 8.002 0 0019.93 13H22v-2h-2.07A8.002 8.002 0 0013 4.07V2m-2 4.08V8h2V6.09c2.5.41 4.5 2.41 4.92 4.91H16v2h1.91c-.41 2.5-2.41 4.5-4.91 4.92V16h-2v1.91C8.5 17.5 6.5 15.5 6.08 13H8v-2H6.09C6.5 8.5 8.5 6.5 11 6.08M12 11a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1z" />
      </svg>
    );
  }
}

export class ICON_X extends Component {
  //
  // Feather Icons:
  // Chevron Down
  //
  // https://feathericons.com/#x
  //
  // MIT
  // https://github.com/feathericons/feather/blob/master/LICENSE
  //

  render() {
    const style = this.props.style;
    const fill = this.props.fill ? this.props.fill : "none";
    const height = this.props.height ? this.props.height : 24;
    const width = this.props.width ? this.props.width : 24;
    const stroke = this.props.stroke ? this.props.stroke : "#ffffff";
    const strokeWidth = this.props.strokeWidth ? this.props.strokeWidth : 2;
    const viewBox = this.props.viewBox ? this.props.viewBox : "0 0 24 24";

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={style}
        width={width}
        height={height}
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        className="feather feather-x"
        viewBox={viewBox}
      >
        <path d="M18 6L6 18"></path>
        <path d="M6 6L18 18"></path>
      </svg>
    );
  }
}
