import { NavSplitLoading, NavSplitError, NavRouteError } from "@artsman/react-nav-ui";
import Root from "./Root";

export const routeOptions = {
  addParamProps: true,
  routeProp: false,
  matchProp: false
};

export const routes = [
  {
    path: "/",
    exact: true,
    redirect: "/events"
  },
  {
    path: "/fetch",
    exact: true,
    componentPath: "Fetch",
    props: {
      SplitLoadingComponent: NavSplitLoading,
      SplitErrorComponent: NavSplitError,
      inverse: false
    }
  },
  {
    path: ["/events", "/venues", "/genres"],
    componentPath: "CityRoot",
    props: {
      SplitLoadingComponent: NavSplitLoading,
      SplitErrorComponent: NavSplitError,
      inverse: false
    },
    routes: [
      {
        path: ["/events", "/venues", "/genres"],
        componentPath: "CityDataRoot",
        routes: [
          {
            path: "/events",
            exact: true,
            componentPath: "Events"
          },
          {
            path: "/venues",
            exact: true,
            componentPath: "Venues"
          },
          {
            path: "/events/:eventId",
            componentPath: "EventsDetail",
            props: {
              SplitLoadingComponent: NavSplitLoading,
              SplitErrorComponent: NavSplitError,
              inverse: false
            }
          },
          {
            path: "/venues/:venueId",
            componentPath: "VenuesDetail",
            props: {
              SplitLoadingComponent: NavSplitLoading,
              SplitErrorComponent: NavSplitError,
              inverse: false
            }
          },
          {
            path: "/genres/:genre",
            componentPath: "GenresDetail",
            props: {
              SplitLoadingComponent: NavSplitLoading,
              SplitErrorComponent: NavSplitError,
              inverse: false
            }
          }
        ]
      }
    ]
  },
  {
    component: NavRouteError
  }
];

export const routesComponent = Root;
