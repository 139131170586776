import React, { useReducer, useEffect, useContext } from "react";

const LOADING_BUNDLE = { loading: true, error: null, data: null };

export const BASE_BUNDLE = { loading: false, error: null, data: null };

const createBadResponseError = response =>
  new Error("bad response" + response && response.status ? " " + response.status : "");

const FetchContext = React.createContext({});

const fetchReducer = (state, action) => {
  switch (action.type) {
    case "SET_BUNDLE": {
      const { url, bundle } = action;
      const oldBundle = state[url];
      if (oldBundle !== bundle) {
        state = {
          ...state,
          [url]: oldBundle && oldBundle.data && !bundle.data ? { ...bundle, data: oldBundle.data } : bundle
        };
      }
      return state;
    }
    case "LOAD_URL": {
      const { url } = action;
      const oldBundle = state[url];
      if (url && oldBundle !== LOADING_BUNDLE) {
        const { setBundle } = state; // TODO - is this safe?
        const updateBundle = bundle => setBundle(url, { ...BASE_BUNDLE, ...bundle });
        fetch(url).then(
          response => {
            if (response.ok) {
              return response.json().then(
                data => {
                  updateBundle({ data });
                },
                error => {
                  updateBundle({ error });
                }
              );
            } else {
              updateBundle({ error: createBadResponseError(response) });
            }
          },
          error => {
            updateBundle({ error });
          }
        );
        state = {
          ...state,
          [url]: oldBundle && oldBundle.data ? { ...LOADING_BUNDLE, data: oldBundle.data } : LOADING_BUNDLE
        };
      }
      return state;
    }
    default:
      return state;
  }
};

const initialFetchStore = {};

export const FetchProvider = props => {
  const { context = FetchContext, children } = props;
  const { Provider } = context;
  const [fetchStore, fetchDispatch] = useReducer(fetchReducer, initialFetchStore);
  // TODO - is this safe?
  fetchStore.setBundle = (url, bundle) => fetchDispatch({ type: "SET_BUNDLE", url, bundle });
  fetchStore.loadUrl = url => fetchDispatch({ type: "LOAD_URL", url });

  return <Provider value={fetchStore}>{children}</Provider>;
};

export const Fetch = props => {
  const { fetchProps, context = FetchContext, children, ...otherProps } = props;
  const fetchStore = useContext(context);
  const fetchPropNames = fetchProps ? Object.keys(fetchProps) : [];

  const childProps = { ...otherProps };
  if (fetchPropNames) {
    fetchPropNames.forEach(fetchPropName => {
      const bundle = fetchStore[fetchProps[fetchPropName]];
      childProps[fetchPropName] = bundle ? bundle : LOADING_BUNDLE;
    });
  }

  // on mount or fetchProps change call loadUrl for each url in the fetchProps values
  useEffect(() => {
    if (fetchPropNames) {
      const { loadUrl } = fetchStore; // TODO - is this safe?
      fetchPropNames.forEach(fetchPropName => {
        loadUrl(fetchProps[fetchPropName]);
      });
    }
  }, [fetchProps]);

  return children(childProps);
};
