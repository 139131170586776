const defaultRouterBasePath = "/";
const defaultGlobalConfigKey = "__config";
const defaultFetchBasePath = "/api/v1/";

const configKeys = ["routerBasePath", "fetchBasePath"];

class Config {
  constructor(globalConfigKey = defaultGlobalConfigKey) {
    this._routerBasePath = defaultRouterBasePath;
    this._fetchBasePath = defaultFetchBasePath;
    if (process.env.NODE_ENV !== "production" && typeof FORCED_FETCH_BASE_PATH !== "undefined") {
      this._fetchBasePath = FORCED_FETCH_BASE_PATH;
    }

    if (window[globalConfigKey] !== undefined) {
      let config = window[globalConfigKey];
      for (let configKey of configKeys) {
        if (config[configKey] !== undefined) {
          this["_" + configKey] = config[configKey];
        }
      }
    }
  }

  getRouterBasePath() {
    return this._routerBasePath;
  }

  getFetchBasePath() {
    return this._fetchBasePath;
  }
}

export const config = new Config();
