var cbs,
	data,
	error = false;
module.exports = function(successCallback, errorCallback) {
	errorCallback = errorCallback || function() {};
	if (data) {
		successCallback(data);
	} else {
		if (error) {
			// Try again.
			requireBundle();
		}
		cbs.push({
			success: successCallback,
			error: errorCallback
		});
	}
};
function requireBundle() {
	cbs = [];
	require.ensure([], function() {
		data = require("!!../../../node_modules/babel-loader/lib/index.js??ref--4!./GenresDetail.js");
		for(var i = 0, l = cbs.length; i < l; i++) {
			cbs[i].success(data);
		}
		error = false;
		cbs = null;
	}, function() {
		for(var i = 0, l = cbs.length; i < l; i++) {
			cbs[i].error.apply(this, arguments);
		}
		error = true;
		cbs = null;
	}, "route-GenresDetail");
}
requireBundle();