/* eslint no-console: 0 */
// eslint-disable-next-line import/no-unresolved
import { registerEvents, applyUpdate } from "serviceworker-events";
import { init as sentry, withScope as sentryScope, captureException as sentryCapture } from "@sentry/browser";
import { renderRoutes } from "@artsman/react-nav-ui";

import { config } from "./config";
import { getDebugStateFromSearch, getSearchFromDebugState } from "./data/debug";

import "./ticket-trove-web.css";
import "./tt-web.scss";

const routerBasePath = config.getRouterBasePath();
const container = document.getElementById("root");

let splitErrorCallback = () => {};
if (process.env.NODE_ENV === "production") {
  // key and version are injected by webpack...
  sentry({
    dsn: SENTRY_KEY,
    release: VERSION
  });
  splitErrorCallback = (error, info) => {
    sentryScope(scope => {
      if (info) {
        Object.keys(info).forEach(key => {
          scope.setExtra(key, info[key]);
        });
      }
      sentryCapture(error);
    });
  };
}

const enhanceHistory = history => {
  const enhancedHistory = {
    ...history
  };

  enhancedHistory.push = (...args) => {
    let overridePushArgs = null;
    if (
      args.length === 1 &&
      history.location &&
      history.location.search &&
      history.location.search.indexOf("debug=true") !== -1
    ) {
      const { debugState } = getDebugStateFromSearch(history.location.search);
      const debugSearch = getSearchFromDebugState("", debugState);
      if (typeof args[0] === "string") {
        const pathParts = args[0].split("?");
        const pathname = pathParts[0];
        const search = pathParts.length > 1 ? pathParts[1] : null;
        overridePushArgs = {
          pathname,
          search: search ? search + "&" + debugSearch : debugSearch
        };
      } else if (typeof args[0] === "object") {
        const { pathname, search = "" } = args[0];
        if (search.indexOf("debug") === -1) {
          overridePushArgs = {
            pathname: pathname,
            search: search ? search + "&" + debugSearch : debugSearch
          };
        }
      }
    }
    if (overridePushArgs) {
      history.push(overridePushArgs);
    } else {
      history.push(...args);
    }
  };

  return enhancedHistory;
};

function renderApp() {
  // Create Root component with base routes wrapped in Redux store
  renderRoutes(routerBasePath, container, { splitErrorCallback, enhanceHistory });
}

function loadPage() {
  if (
    SW_ENABLED &&
    "serviceWorker" in navigator &&
    (window.location.protocol === "https:" || window.location.hostname === "localhost")
  ) {
    const registration = navigator.serviceWorker.register(SW_PATH /*{ scope: '/' }*/);

    if (navigator.serviceWorker.controller) {
      console.log("[index sw] service worker already installed");
      renderApp();
    } else {
      console.log("[index sw] service worker not already installed, waiting for installation");
      navigator.serviceWorker.ready.then(() => {
        console.log("[index sw] service worker newly installed");
        // This seemed to work even when shift-reload is used which makes the controller null even if the service worker was previously activated
        renderApp();
      });

      // navigator.serviceWorker.oncontrollerchange = function () {
      //   console.log('[index sw] service worker on controller change: ' + this.state);
      //   this.controller.onstatechange = function () {
      //     console.log('[index sw] service worker on state change: ' + this.state);
      //     if (this.state === 'activated') {
      //       console.log('[index sw] service worker newly installed');
      //       renderApp();
      //     }
      //   };
      // };
    }

    registerEvents(registration, {
      onInstalled: () => {
        console.log("[index sw] onInstalled");
        renderApp();
      },
      onUpdateReady: () => {
        console.log("[index sw] onUpdateReady");
        applyUpdate().then(() => {
          window.location.reload();
          // TODO - might want to clear cache when reloading????
        });
        // renderApp();
      },

      onUpdating: () => {
        console.log("[index sw] onUpdating");
      },
      onUpdateFailed: () => {
        console.log("[index sw] onUpdateFailed");
      },
      onUpdated: () => {
        console.log("[index sw] onUpdated");
      }
    });
  } else {
    if (SW_ENABLED) {
      console.log("[index sw] serviceWorker not available");
    } else {
      console.log("[index sw] serviceWorker disabled");
    }
    renderApp();
  }
}

loadPage();
